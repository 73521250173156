'use client';

import { Image, ImageProps } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import useAppLanguage from '../../../../hooks/useAppLanguage';
import useAuth from '../../../../hooks/useAuth';
import mark from '../../../../public/images/logos/logo-mark.png';
import { checkHasBrandingData } from '../../../../utils/utils';

const LogoMark: React.FC<ImageProps> = ({ src, objectFit, maxWidth, maxHeight, ...rest }) => {
  const { translate } = useAppLanguage();
  const { brandingData } = useAuth();

  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const logoUrl = useMemo(() => {
    if (hasBrandingData && brandingData?.logo) {
      return brandingData.logo;
    } else {
      return mark.src;
    }
  }, [brandingData, hasBrandingData]);

  return (
    <Image
      src={logoUrl ?? ''}
      objectFit="contain"
      maxWidth="100%"
      maxHeight="100%"
      alt={translate('Wonderschool Logo')}
      {...rest}
    />
  );
};
export default LogoMark;
