'use client';

import { Image, ImageProps, useColorModeValue } from '@chakra-ui/react';
import React, { useMemo } from 'react';

import useAppLanguage from '../../../../hooks/useAppLanguage';
import useAuth from '../../../../hooks/useAuth';
import logoWordLight from '../../../../public/images/logos/logo-word-white.png';
import logoWordDark from '../../../../public/images/logos/logo-word.png';
import { checkHasBrandingData } from '../../../../utils/utils';

const Logo: React.FC<ImageProps> = ({ src, objectFit, maxWidth, maxHeight, ...rest }) => {
  const { translate } = useAppLanguage();
  const logoByColor = useColorModeValue(logoWordDark, logoWordLight);
  const { brandingData } = useAuth();

  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const logoUrl = useMemo(() => {
    if (hasBrandingData && brandingData?.logo) {
      return brandingData.logo;
    } else {
      return logoByColor.src;
    }
  }, [brandingData, logoByColor.src, hasBrandingData]);

  return (
    <Image
      src={logoUrl ?? ''}
      objectFit="contain"
      maxWidth="100%"
      maxHeight="100%"
      alt={translate('Wonderschool Logo')}
      {...rest}
    />
  );
};
export default Logo;
