'use client';

import React, { useMemo } from 'react';

import useAppLanguage from '../../../../hooks/useAppLanguage';
import useAuth from '../../../../hooks/useAuth';
import mark from '../../../../public/images/logos/logo-mark.png';
import { checkHasBrandingData } from '../../../../utils/utils';

const LogoMarkV2: React.FC<React.ImgHTMLAttributes<HTMLImageElement>> = ({ src, ...rest }) => {
  const { translate } = useAppLanguage();
  const { brandingData } = useAuth();

  const hasBrandingData = useMemo(() => {
    return checkHasBrandingData(brandingData);
  }, [brandingData]);

  const logoUrl = useMemo(() => {
    if (hasBrandingData && brandingData?.logo) {
      return brandingData.logo;
    } else {
      return mark.src;
    }
  }, [brandingData, hasBrandingData]);

  return (
    <img
      className="max-w-full max-h-full object-contain"
      src={logoUrl ?? ''}
      alt={translate('Wonderschool Logo')}
      {...rest}
    />
  );
};
export default LogoMarkV2;
