'use client';

import { ChakraBaseProvider, ColorModeScript } from '@chakra-ui/react';
import { createContext, Suspense } from 'react';
import { HelmetProvider } from 'react-helmet-async';

import theme from '../theme/chakra';
import GlobalFontModerat from '../theme/chakra/baseFont';
import AuthProvider from './AuthProvider';
import LanguageProvider from './LanguageProvider';
import LaunchDarklyProvider from './LaunchDarklyProvider';
import RollbarProvider from './RollbarProvider';

const ApplicationContext = createContext(null);

const ChakraWrapper: React.FC<React.PropsWithChildren> = ({ children }) => {
  return (
    <ChakraBaseProvider theme={theme}>
      <>
        <ColorModeScript />
        <GlobalFontModerat />
        {children ?? null}
      </>
    </ChakraBaseProvider>
  );
};

// This is a high level provider that wraps the entire application.
// Useful to wrap App with application-level providers.
// If there's a need to define application level state, it can be set as ApplicationContext.Provider.value.
export default function ApplicationProvider({ children }: React.PropsWithChildren) {
  return (
    <ApplicationContext.Provider value={null}>
      {/* Wrap everything with suspense provider, i18n errors out if there's no top level Suspense */}
      <Suspense>
        <RollbarProvider>
          <LaunchDarklyProvider>
            {/* Authentication Provider */}
            <AuthProvider>
              {/* Language Provider */}
              <LanguageProvider>
                {/* Helmet Provider */}
                <HelmetProvider>
                  {/* Chakra Wrapper with Provider */}
                  <ChakraWrapper>{children ?? null}</ChakraWrapper>
                </HelmetProvider>
              </LanguageProvider>
            </AuthProvider>
          </LaunchDarklyProvider>
        </RollbarProvider>
      </Suspense>
    </ApplicationContext.Provider>
  );
}
